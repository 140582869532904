.Overlay {
    position: absolute;
    background-color: black;
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    z-index: 20;
    opacity: 0;
    pointer-events: none;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }
}

.Items {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Title {
    color: white;
    text-align: center;
    font-size: 3em;
    font-weight: 800;
    margin: 20px;
}

.TitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.StartButton {
    background-color: black;
    outline: white 2px solid;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    flex-grow: 1;
    text-align: center;
    width: 60px;
    max-height: 20px;
    padding: 10px;
    margin: 6px 20px;

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.StartButton:hover {
    outline-color: lime;
    color: lime;

    &.disabled {
        outline-color: white;
        color: white;
    }
}

.ButtonLink {
    color: inherit;
    text-decoration: none;
}

.StartInfo {
    color: white;
    margin: auto;
    text-align: center;
    margin-bottom: 10px;
}

.PlayerIdContainer {
    max-width: 350px;
    margin: auto;
    margin-bottom: 25px;
}
