.BattleSimulatorWrapper {
    background-color: black;
    background-size: cover;
    width: 100%;
    height: 100%;
    outline: 2px solid white;
    /* box-shadow: inset 0 0 64px rgba(255, 255, 255, 0.4); */
}

.SimulatorContainer {
    width: 100%;
    height: 100%;
}
