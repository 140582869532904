.ShipHangarWrapper {
    width: 520px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.ShipHangarOptionContainer {
    background-color: black;
    outline: white 2px solid;
}

.ShipHangarShadow {
    width: 520px;
    height: 100px;
    position: absolute;
    box-shadow: 0 -50px 105px 10px rgba(0, 0, 0, 1);
}

h2 {
    color: white;
    text-align: center;
    margin: 2px;
    padding: 0px;
    margin-bottom: 0px;
}

h4 {
    color: white;
    text-align: center;
}

p {
    color: white;
    text-align: center;
    margin: 0px;
    margin-bottom: 10px;
}

.CraftingResponseLog {
    margin-bottom: 9px;
    opacity: 0;

    &.visible {
        opacity: 1;
    }
}

.ShipHangarOptionList {
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    justify-content: center;
    height: calc(100vh - 415px);
    overflow: scroll;
    overflow-x: hidden;

    @media (max-device-width: 800px) {
        /* Subtract ship generator height */
    }
}

.ShipGenerator {
    background-color: black;
    border-top: 2px white solid;
    border-left: 2px white solid;
    padding: 0px 10px;
    height: 222px;
    position: relative;
}

.GeneratorSlot {
    outline: 2px white solid;
    border-radius: 8px;
    transition: 0.5s linear;
    &.left {
        outline: none;
        translate: 100% 0;
        transition: 1s ease;
        animation: spin 2s linear infinite reverse;
    }
    &.right {
        outline: none;
        translate: -100% 0;
        transition: 1s ease;
        animation: spin 1.5s linear infinite;
    }
    &.empty {
        background-color: #bbb;
        transition: 0.3s linear;
    }
}

.LoadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HalfDiscSpinner {
    border-top: 3px solid #ffff;
    border-right: 3px solid transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

.LoadingText {
    position: absolute;
    font-weight: 800;
    margin-top: 150px;
    font-family: Arial, sans-serif; /* Your desired font */
    color: #ffff; /* Match the color of the spinner or as per your preference */
    opacity: 0;

    &.visible {
        opacity: 1;
        animation: loadAnim 2s linear infinite;
    }
}

.CraftingResponseLog {
    color: white;
    margin: auto;
    text-align: center;

    &.ERROR {
        color: red;
    }

    &.INFO {
        color: white;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(0.7);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}

@keyframes loadAnim {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.GeneratorComponents {
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 1;
    position: relative;
    &.invisible {
        display: none;
        visibility: hidden;
        position: relative;
        opacity: 0;
        transition: 0.2s ease;
    }
}

.GeneratorComplete {
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 0;
    height: 0%;
    &.visible {
        height: 100%;
        opacity: 1;
        transition: 1s ease;
        margin-top: -30px;
        overflow: hidden;
    }
}

.PrettyCode {
    width: 200px;
    height: 300px;
    /* margin-left: -200px; */
    overflow-x: scroll;
    overflow-y: scroll;
}
