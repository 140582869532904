.BattleSequenceWrapper {
    position: absolute;
    top: 5%;
    display: flex;
    flex-direction: column;
    z-index: 10;
    transition: 0.5s ease;

    &.visible {
        transition: 0.5s 0.2s;
    }

    &.battleOver {
        scale: 1;
        translate: 0 50%;
    }
}

.BattleSequenceData {
    display: flex;
    flex-direction: column;
    scale: 0.5;
    transition: 0.1s linear;

    &.battleOver {
        scale: 1;
    }

    &.visible {
    }
}

.BattleSequenceData:hover {
    scale: 0.7;
    translate: 0 15%;

    &.battleOver {
        scale: 1;
        translate: 0 0;
    }
}

.BattleSequence {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    transition: 0.2s ease;
    /* background-color: black; */
    outline: 2px solid white;
}

.BattleSequenceItem {
    scale: 0.5;
    transition: 0.2s ease;

    &.defeated {
        opacity: 0.3;
        scale: 0.5;
    }
    &.active {
        scale: 1;
    }
}

.BattleSequenceSpace {
    width: 32px;
    outline: 2px dashed white;
    margin: auto;
    margin-left: -15px;
    margin-right: -15px;
    transition: 0.2s ease;

    &.defeated {
        outline: 4px solid white;
    }

    &.final {
        opacity: 0;
    }
}

.ContinueButton {
    background-color: black;
    outline: 2px solid white;
    margin: auto;
    margin-top: 20px;
    padding: 5px;
    text-align: center;
    color: white;
    border-radius: 8px;
    /* opacity: 0; */
    visibility: hidden;

    &.battleOver {
        opacity: 1;
        transition: 0.2s ease;
        visibility: visible;
    }
}

.ContinueButton:hover {
    color: lime;
    outline-color: lime;
    cursor: pointer;
}

.BattleSequenceTitle {
    color: white;
    background-color: black;
    text-align: center;
    margin: auto;
    padding: 0 30px 0 30px;
    translate: 0 10px;
    z-index: 102;
}

.UnknownShip {
    width: 120px;
    height: 120px;
    color: white;
    font-size: 70px;
    text-align: center;
    cursor: default;
    outline: 3px white dashed;
    border-radius: 100%;
    justify-content: center;
    line-height: 120px;
    margin: auto;
    translate: 0 20px;
    margin-left: 10px;
}

h2 {
    line-height: 50px;
    text-align: center;
}
