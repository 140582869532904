.PlayerInfo {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-around;
    padding: 10px;
    gap: 5px;
}

.PlayerInfoHeader {
    color: white;
    font-size: small;
    flex-grow: 1;
    line-height: 25px;
    text-align: center;
}

.PlayerInput {
    font-size: 16px;
    height: 20px;
    border: 2px solid white;
    background-color: black;
    color: white;
    flex-grow: 3;

    &.disabled {
        border: none
    }

    &.dirty {
        border-color: yellow;
    }

    &.invalid {
        border-color: red;
    }
}

.PlayerInput:hover {
    border-color: lime;

    &.dirty {
        border-color: yellow;
    }

    &.invalid {
        border-color: red;
    }
}

.PlayerInfoId {
    display: flex;
    gap: 10px;
}

.SetButton {
    background-color: black;
    outline: white 2px solid;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    flex-grow: 1;
    max-width: 150px;

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.SetButton:hover {
    outline-color: lime;
    color: lime;

    &.disabled {
        outline-color: white;
        color: white;
    }
}