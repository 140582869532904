.ShipInfoContainer {
    border-top: 2px solid white;
    border-right: 2px solid white;
    position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    min-height: 180px;
    width: 100%;
    /* max-width: 800px; */
    padding: 20px;
    background-color: black;
    color: white;
}

.ShipPopupWrapper {
    position: absolute;
    /* top: 100%; */
    translate: 0 40px;
    top: 50%;
    width: 200px;
    padding: 15px;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }
}

.ShipPopup {
    padding: 20px 20px 0px 20px;
    background-color: black;
    border: 2px solid #ddd;
    color: white;

    &.crowned {
        border-color: gold;
    }

    &.broken {
        border-color: red;
    }
}

.ShipPopupName {
    color: white;
    background-color: black;
    padding: 0;
    text-align: center;
    margin: auto;
    margin-top: -30px;
    margin-bottom: 10px;

    &.crowned {
        color: gold;
    }

    &.broken {
        color: red;
    }
}

.ShipInfo {
    margin: 10px;
    margin-left: -5px;
    margin-right: -5px;
}

.ShipDetails {
    color: white;
    text-align: left;
    padding-top: 5px;
}

.ShipDetailItem {
    margin-bottom: 5px;

    &.Crowned {
        color: gold;
    }

    &.Owned {
        color: turquoise;
    }
}

.ShipDetailItemTitle {
    font-weight: 800;
}

.PopupOptions {
    display: flex;
    translate: 0 -15px;
    justify-content: flex-end;
}

.PopupButton {
    margin: 5px;
    flex-grow: 1;
    background-color: black;
    color: white;
    border: white 2px solid;
    text-align: center;
    border-radius: 8px;

    &.Disabled {
        opacity: 0.5;
    }

    &.Report {
        max-width: 50%;
    }
}

.PopupButton:hover {
    border-color: lime;
    color: lime;
    cursor: pointer;

    &.Disabled {
        color: white;
        border-color: white;
        cursor: default;
    }

    &.Report {
        border-color: red;
        color: red;
    }
}

.ShipDisplayWrapper {
    position: relative;
    margin: 10px;
    border-radius: 8px;
    width: 25%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &.large {
        border-radius: 8px;
        width: 120px;
        height: 120px;
        cursor: default;

        @media (max-device-width: 800px) {
            width: 50px;
            height: 50px;
        }
    }

    &.broken {
        outline: red 2px solid;
    }

    @media (max-device-width: 800px) {
        margin: 6px;
        width: 40px;
        height: 40px;
    }
}

.ShipDisplayWrapper:hover {
    &.hoverable {
        outline: white 2px dotted;
    }
}

.ShipText {
    text-align: center;
    color: white;
    font-size: 12px;

    &.large {
        font-size: 40px;
    }

    &.clickable {
        font-size: 40px;
        cursor: pointer;
    }

    @media (max-device-width: 800px) {
        font-size: 8px;
    }
}

.ShipDisplayIcon {
    position: absolute;
    margin: auto;
    top: -12px;
    right: -12px;
    width: 16px;
    height: 16px;
    background-color: black;
    outline: 2px solid white;
    color: white;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    &.left {
        left: -8px;
    }
}

.ShipDisplayIcon:hover {
    color: lime;
    outline-color: lime;
    cursor: pointer;
}

.ShipDisplayPolygon {
    z-index: 2;
}

.ShipMedals {
    display: flex;
    justify-content: flex-start;
    position: absolute;
}

.ShipMedal {
    /* position: absolute; */
    /* transform: scale(0.3) rotate(30deg); */
    z-index: 0;
    opacity: 0.2;
    width: 30px;
    height: 30px;

    &.hovered {
        opacity: 1;
        transform: scale(1.1) rotate(60deg);
        transition: 0.2s ease;
    }
}

.MedalPoly {
    transform: scale(0.3);
}

.BrokenText {
    opacity: 0;
    text-align: center;
    rotate: 7deg;

    &.broken {
        opacity: 1;
        color: red;
    }
}
