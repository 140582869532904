.AppWrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;
    overflow: hidden;
}

.BodyWrapper {
    display: flex;
    height: 100%;
    width: 100%;
}

.BattleSquadWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.BattleWrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.Sidebar {
    display: flex;
    flex-direction: column;
    background-color: black;
    outline: white 2px solid;
}
