.Navigation {
    display: flex;
    justify-content: space-between;
    background-color: black;
    outline: 2px white solid;
    padding: 10px 64px 10px 16px;
    z-index: 15;
}

.NavOption {
    color: white;
    text-align: center;
    cursor: default;
    line-height: 40px;
    height: 40px;

    &.Debug {
        color: yellow;
    }

    a {
        color: white;
        text-decoration: none;
    }

    a:hover {
        color: lime;
        cursor: pointer;
    }
}

.NavOption:hover {
    color: lime;
}

.NavTitle {
    color: white;
    text-align: center;
    font-size: larger;
    font-weight: 800;
    margin-top: 6px;
    display: flex;
}

.NavTitleText {
    font-size: 30px;

    a {
        color: white;
        text-decoration: none;
    }

    a:hover {
        color: lime;
        cursor: pointer;
    }
}

.NavTitleAlpha {
    /* position: relative; */
    rotate: 25deg;
    color: black;
    font-size: 10px;
    font-weight: 1000;
    background-color: white;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 100%;
    margin-top: -5px;

    &.Splash {
        width: 70px;
        width: 70px;
        height: 70px;
        font-size: 18px;
        line-height: 70px;
    }
}

/* Options */

.MenuOptions {
    position: absolute;
    translate: 50px -10px;
    background-color: black;
    outline: white 2px solid;
    opacity: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: auto;
    pointer-events: none;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    .Option {
        color: white;
        background-color: black;
        outline: white 2px solid;
        padding: 5px 10px;
    }

    .Option:hover {
        color: lime;
    }
}
